import React, { useState, useEffect } from 'react';
import * as throttle from 'lodash.throttle';
import NavBar from './NavBar';
import { useMenu } from '../../library/MenuContext';

export const Header = (props: any) => {

  // using menu to collapse theme list when header collapses
  const { toggleThemeList } = useMenu();

  // define scroll metrics
  let scrollPos: number = window.scrollY;
  const scrollThrottle: number = 100;
  const scrollThreshold: number = 60;

  // local header state
  const [headerState, setHeaderState] = useState({
    show: true,
    initialised: false,
  });

  // listen to window events using windowThrottle
  useEffect(() => {
    window.addEventListener('scroll', windowThrottle);
    return (() => {
      window.removeEventListener("scroll", windowThrottle);
    })
  }, []);

  // on scroll logic, called after scroll throttle duration
  const throttleCallback = () => {

    const scrollYPastThreshold = window.scrollY > scrollThreshold;
    const scrollYPastPrevious = window.scrollY > scrollPos;
    const activelyScrollingDown = scrollYPastThreshold && scrollYPastPrevious;
    const activelyScrollingUp = window.scrollY < scrollPos;

    // hide header if user is actively scrolling down and header is still showing
    if ((activelyScrollingDown && headerState.show === true)) {
      setHeaderState({ show: false, initialised: true });

      // time theme menu collapse with animation
      setTimeout(() => {
        toggleThemeList(false);
      }, 500);
    }

    // always show header if user is actively scrolling up
    if (activelyScrollingUp) {
      setHeaderState({ show: true, initialised: true });
    }
    scrollPos = window.scrollY;
  }

  // define window throttle function
  const windowThrottle = throttle(throttleCallback, scrollThrottle, { trailing: true, leading: false });

  return <NavBar headerState={headerState} />;
}

export default Header;