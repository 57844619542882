import React, { useState, useEffect } from 'react';
import { Section, SubSection, ButtonRow, BlogWrapper } from '../../library/StyledComponents';
import { Button } from '../../library/Button';
import styled from 'styled-components';
import { API_URL } from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import ArticleLoader from './ArticleLoader';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';


const StyledBlog = styled.div`
  display: flex;
  flex: 1;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  word-wrap: break-word;
  position: relative;
  width: 100%;
  padding-top: 50px;
`;

export const Article: React.FC = () => {

  const navigate = useNavigate();
  const { slug }: any = useParams();

  /* not currently in use
  const { state }: any = useLocation();
  let { meta } = state;
  */

  const [article, setArticle] = useState({
    loaded: false,
    articles: [],
  });

  const { loaded, articles } = article;

  useEffect(() => {
    fetch(API_URL + '/blog/get', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({
        slug: slug
      })
    }).then(res => res.json())
      .then(data => {

        setArticle({
          loaded: true,
          articles: data.article
        });
      })
      .catch(e => {
      });
  }, []);

  return (
    <>
      <Section>
        <SubSection>
          <StyledBlog>
            <BlogWrapper>

              <ButtonRow style={{ marginBottom: '2rem' }}>
                <Button label="Blog Home" onClick={() => { navigate('/blog') }} />
              </ButtonRow>

              {!loaded && <>
                <h1>JKRB Blog</h1>
                <h3>Loading Article...</h3>
                <ArticleLoader />
              </>}

              {loaded && (
                articles.length == 0
                  ? <h2>No Articles to Display</h2>
                  :
                  articles.map((item, index) => {

                    // force new lines
                    item.content = item.content.replace(/\\n/g, '\n');

                    return (
                      <React.Fragment key={index}>
                        <h1 className='title'>{item.title}</h1>
                        <h3 className='subtitle'>{moment.unix(item.timestamp).format("MMM Do YYYY")}, by {item.author}</h3>

                        <div className='head'>
                          <h5 className='category'>{item.category}</h5>
                        </div>

                        <article>
                          <ReactMarkdown>{item.content}</ReactMarkdown>
                        </article>
                      </React.Fragment>
                    );
                  })
              )}
            </BlogWrapper>
          </StyledBlog>
        </SubSection>
      </Section>
    </>
  )
}

export default Article;