import React from 'react';
import styled from 'styled-components';
import { containerWidths } from '../../../constants';
import { Element } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Anchor, Section, SubSection, ButtonRow } from '../../../library/StyledComponents';
import { Button } from '../../../library/Button';
import { faEnvelope, faMailBulk, faMailReply, faMailReplyAll, faPaperPlane, faX } from '@fortawesome/free-solid-svg-icons';

const StyledContact = styled.div<any>`
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  h1 {
    margin: 0 1rem 0.5rem 0rem;
    padding: 0 1rem;
    word-break: break-all;
    word-wrap: break-word;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  @media(max-width: ${containerWidths.tablet}px) {
    h1 {
      font-size: 1.9rem;
    }
  }

  @media(max-width: ${containerWidths.mobile}px) {
    h1 {
      font-size: 1.45rem;
    }
  }

  .email {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-flow: row wrap;
    padding: 0.25rem 0.5rem 0 0.5rem;

    > button {
      margin: 0 0.6rem;
    }
  }
  > div {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    align-content: center;
  }
`;

export const Contact: React.FC = () => {

  return (
    <Section>
      <Anchor>
        <Element name="contactUs"></Element>
      </Anchor>
      <SubSection>
        <StyledContact>
          <div className='contact'>
            <div>
              <h1>Contact us</h1>
              <h2>
              JKRB support reputable blockchain projects that we feel have strong fundamentals and contribute to the overall decentralized ecosystem.
              </h2>
              <h2 style={{ marginBottom: '2rem'}}>
                If you would like JKRB to be a part of your chain, please send us an enquiry.
              </h2>
              <ButtonRow>
              <a href='mailto:contact@jkrb.io'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button label="Email us" icon={<FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 10, }} />} />
                </a>

                <a href='https://www.linkedin.com/company/11080617'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button label="Follow us on LinkedIn" icon={<FontAwesomeIcon icon={faLinkedin} transform="grow-4" style={{ marginRight: 10, }} />} />
                </a>

                <a href='https://twitter.com/jkrbinvestments'
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button label="Follow us on X" icon={<FontAwesomeIcon icon={faXTwitter} transform="grow-3" style={{ marginRight: 10, }} />} />
                </a>
              </ButtonRow>
            </div>
          </div>
        </StyledContact>
      </SubSection>
    </Section>
  );
}

export default Contact;